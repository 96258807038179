/* eslint-disable no-console */
export default [
  {
    path: '/',
    component: '../layouts/BlankLayout',
    routes: [
      {
        path: '/other', // 其他
        component: '../layouts/Other',
        routes: [
          {
            name: 'video',
            path: '/other/video', //
            component: './Video',
          },
          {
            name: 'comprehensive-analysis',
            path: '/other/comprehensive-analysis', //
            component: './ComprehensiveAnalysis',
          },
          {
            name: 'operation-video',
            path: '/other/operation-video',
            component: './OperationVideo',
          },
        ],
      },
      {
        path: '/open-window', // 弹窗
        component: '../layouts/OpenWindow',
        routes: [
          {
            path: '/',
            component: '../layouts/Middleware', // 拦截器
            routes: [
              {
                path: '/open-window/business',
                name: 'business',
                icon: 'smile',
                routes: [
                  {
                    path: '/open-window/business/ecg',
                    name: 'ecg',
                    icon: 'smile',
                    routes: [
                      {
                        name: 'ecg-reports-edit', // ECG分析报告编辑
                        path: '/open-window/business/ecg/ecg-reports-edit',
                        component: './OpenWindow/Business/Ecg/EcgReportsEdit',
                        bussinessNo: 2000025,
                      },
                      {
                        name: 'screen-detail', // 智能筛查
                        path: '/open-window/business/ecg/screen-detail',
                        component: './OpenWindow/Business/Ecg/ScreenDetail',
                        bussinessNo: 2000041,
                      },
                      {
                        name: 'confirm-event', // 事件认定
                        path: '/open-window/business/ecg/confirm-event',
                        component: './OpenWindow/Business/Ecg/ConfirmEvent',
                        bussinessNo: 2000025,
                      },
                      {
                        name: 'screen-follow-up', // 消费者回访
                        path: '/open-window/business/ecg/screen-follow-up',
                        component: './OpenWindow/Business/Ecg/ScreenFollowUpDetail',
                        bussinessNo: 2000025,
                      },
                      {
                        name: 'ecg-reports-wholePreview', // ECG分析报告编辑-全程预览
                        path: '/open-window/business/ecg/ecg-reports-edit/whole-preview',
                        component:
                          './OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview',
                      },
                      {
                        name: 'ecg-reports-printView', // ECG分析报告编辑-全程预览
                        path: '/open-window/business/ecg/ecg-reports-edit/whole-preview/print-view',
                        component:
                          './OpenWindow/Business/Ecg/EcgReportsEdit/components/EcgWholePreview/PrintView',
                      },
                      {
                        name: 'ekg', // 亿科吉
                        path: '/open-window/business/ecg/rhc-ecg-detail',
                        component: './OpenWindow/Business/Ecg/EcgReportsEdit',
                        bussinessNo: 2000025,
                      },
                      {
                        name: 'ecg-detail', // 心电图详情
                        path: '/open-window/business/ecg/ecg-detail',
                        component: './OpenWindow/Business/Ecg/EcgDetail',
                        bussinessNo: 0,
                      },
                      {
                        name: 'editing-template', // 编辑模板
                        path: '/open-window/business/ecg/editing-template',
                        component: './OpenWindow/Business/Ecg/EditingTemplate',
                        bussinessNo: 0,
                      },
                      {
                        name: 'redux-demo', // redux
                        path: '/open-window/business/ecg/redux-demo',
                        component: './OpenWindow/Business/Ecg/ReduxDemo',
                        bussinessNo: 0,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/',
        component: '../layouts/SecurityLayout',
        routes: [
          {
            path: '/',
            component: '../layouts/BasicLayout',
            authority: ['admin', 'user'],
            routes: [
              {
                path: '/',
                component: '../layouts/Middleware', // 拦截器
                routes: [
                  {
                    path: '/',
                    redirect: '/business/ecg/ecg-reports', // 废弃
                  },
                  {
                    path: '/admin**',
                    name: 'admin',
                    icon: 'FundViewOutlined',
                    id: 345,
                    routes: [
                      {
                        path: '/admin**/business',
                        name: 'business',
                        icon: 'BankOutlined',
                        systemId: 16,
                        id: 16,
                        routes: [
                          {
                            path: '/admin**/business/settings/system',
                            name: 'system',
                            icon: 'SettingOutlined',
                            iconCls: '&#xe734;',
                            id: 345,
                            routes: [
                              // {
                              //   name: 'menuList', // 系统菜单管理
                              //   icon: 'UserAddOutlined',
                              //   path: '/admin**/settings/system/menulist',
                              //   locale: 'menu.settings.system.menu-list',
                              //   component: './Settings/System/MenuList',
                              //   bussinessNo: 1201061,
                              // },
                              {
                                name: 'warning', // 运维预警
                                // icon: 'WarningOutlined',
                                path: '/admin**/business/settings/system/warning',
                                locale: 'menu.business.settings.system.warning',
                                component: './Business/Settings/System/Warning',
                                bussinessNo: 1101050,
                              },
                              {
                                name: 'operation-log', // 操作日志
                                // icon: 'WarningOutlined',
                                path: '/admin**/business/settings/system/operation-log',
                                locale: 'menu.business.settings.system.operation-log',
                                component: './Business/Settings/System/OperationLog',
                                bussinessNo: 1101051,
                              },
                              {
                                name: 'api-logs', // APP请求记录
                                // icon: 'WarningOutlined',
                                path: '/admin**/business/settings/system/api-logs',
                                locale: 'menu.business.settings.system.api-logs',
                                component: './Business/Settings/System/ApiLogs',
                                bussinessNo: 1101052,
                              },
                            ],
                          },
                          {
                            path: '/admin**/business/ecg',
                            name: 'ecg',
                            icon: 'FundOutlined',
                            iconCls: '&#xe61f;',
                            id: 345,
                            routes: [
                              {
                                name: 'users', // 我的病人
                                // icon: 'UserAddOutlined',
                                locale: 'menu.business.ecg.users',
                                path: '/admin**/business/ecg/users',
                                component: './Business/Ecg/Users',
                                bussinessNo: 2000011,
                              },
                              {
                                name: 'ecg-reports', // ECG分析报告
                                // icon: 'icon-file-pdf',
                                path: '/admin**/business/ecg/ecg-reports',
                                locale: 'menu.business.ecg.ecg-reports',
                                component: './Business/Ecg/EcgReports',
                                bussinessNo: 2000025,
                              },
                              {
                                name: 'ecg-records', // 监测记录
                                // icon: 'icon-Pdf',
                                path: '/admin**/business/ecg/ecg-records',
                                locale: 'menu.business.ecg.ecg-records',
                                component: './Business/Ecg/EcgRecords',
                                bussinessNo: 2000013,
                              },
                              {
                                name: 'bindings', // 设备绑定查询
                                path: '/admin**/business/ecg/bindings',
                                locale: 'menu.business.ecg.bindings',
                                component: './Business/Ecg/Bindings',
                                bussinessNo: 2000024,
                              },
                              {
                                name: 'log-list', // APP日志记录
                                path: '/admin**/business/ecg/log-list',
                                locale: 'menu.business.ecg.log-list',
                                component: './Business/Ecg/LogList',
                                bussinessNo: 2000021,
                              },
                              {
                                name: 'warnings', // 危急预警
                                path: '/admin**/business/ecg/warnings',
                                locale: 'menu.business.ecg.warnings',
                                component: './Business/Ecg/Warnings',
                                bussinessNo: 2000015,
                              },
                              {
                                name: 'weak', // 低振幅数据提醒
                                path: '/admin**/business/ecg/weak',
                                locale: 'menu.business.ecg.weak',
                                component: './Business/Ecg/Weak',
                                bussinessNo: 2000039,
                              },
                              {
                                name: 'screen', // 智能筛查
                                path: '/admin**/business/ecg/screen',
                                locale: 'menu.business.ecg.screen',
                                component: './Business/Ecg/Screen',
                                bussinessNo: 2000041,
                              },
                              {
                                name: 'screen-user', // 消费者筛查
                                path: '/admin**/business/ecg/screen-user',
                                locale: 'menu.business.ecg.screen-user',
                                component: './Business/Ecg/ScreenUserConfirm',
                                bussinessNo: 2000046, //46
                              },
                              {
                                name: 'sampling', // 采样异常数据
                                path: '/admin**/business/ecg/sampling',
                                locale: 'menu.business.ecg.sampling',
                                component: './Business/Ecg/Sampling',
                                bussinessNo: 2000042,
                              },
                              {
                                name: 'device', // 设备管理
                                path: '/admin**/business/ecg/device',
                                locale: 'menu.business.ecg.device',
                                component: './Business/Ecg/Device',
                                bussinessNo: 2000044,
                              },
                              {
                                name: 'application', // 申请报告
                                path: '/admin**/business/ecg/report-apply',
                                locale: 'menu.business.ecg.apply',
                                component: './Business/Ecg/ReportApplication',
                                bussinessNo: 2000047,
                              },
                              {
                                name: 'pacemaker', // 起搏器指征查询
                                path: '/admin**/business/ecg/pacemaker',
                                locale: 'menu.business.ecg.pacemaker',
                                component: './Business/Ecg/Pacemaker',
                                bussinessNo: 2000048,
                              },
                              {
                                name: 'dynamic', // 动态检查单申请
                                path: '/admin**/business/ecg/dynamic',
                                locale: 'menu.business.ecg.dynamic',
                                component: './Business/Ecg/DynamicApply',
                                bussinessNo: 2000049,
                              },
                              {
                                name: 'remote', // 遥测检查单申请
                                path: '/admin**/business/ecg/remote',
                                locale: 'menu.business.ecg.remote',
                                component: './Business/Ecg/RemoteApply',
                                bussinessNo: 2000050,
                              },
                              {
                                name: 'preview', // 报告预处理
                                path: '/admin**/business/ecg/preview',
                                locale: 'menu.business.ecg.preview',
                                component: './Business/Ecg/PreviewReport',
                                bussinessNo: 2000051,
                              },
                              {
                                name: 'deep-analysis', // 报告深度分析
                                path: '/admin**/business/ecg/deep-analysis',
                                locale: 'menu.business.ecg.deep-analysis',
                                component: './Business/Ecg/DeepAnalysis',
                                bussinessNo: 2000053,
                              },
                              {
                                name: 'quick-check', // 快速检测记录
                                path: '/admin**/business/ecg/quick-check',
                                locale: 'menu.business.ecg.quick-check',
                                component: './Business/Ecg/QuickCheck',
                                bussinessNo: 2000054,
                              },
                              {
                                name: 'follow-up', // 消费者筛查回访
                                path: '/admin**/business/ecg/follow-up',
                                locale: 'menu.business.ecg.follow-up',
                                component: './Business/Ecg/ScreenFollowUp',
                                bussinessNo: 2000055,
                              },
                            ],
                          },
                          {
                            path: '/admin**/business/online', // 在线问诊
                            name: 'online',
                            icon: 'CommentOutlined',
                            iconCls: '&#xe800;',
                            id: 373,
                            routes: [
                              {
                                name: 'analytic-orders', // 分析订单
                                path: '/admin**/business/online/analytic-orders',
                                locale: 'menu.business.online.analytic-orders',
                                component: './Business/Online/AnalyticOrders',
                                bussinessNo: 2000032,
                              },
                              {
                                name: 'signature', // 签名审核
                                path: '/admin**/business/online/signature',
                                locale: 'menu.business.online.signature',
                                component: './Business/Online/Signature',
                                bussinessNo: 2000035,
                              },
                              {
                                name: 'interpretation-orders', // 分析解读
                                path: '/admin**/business/online/interpretation-orders',
                                locale: 'menu.business.online.interpretation-orders',
                                component: './Business/Online/InterpretationOrders',
                                bussinessNo: 2000030,
                              },
                              {
                                name: 'intelligent-screen', // 智能筛查订单
                                path: '/admin**/business/online/intelligent-screen',
                                locale: 'menu.business.online.intelligent-screen',
                                component: './Business/Online/IntelligentScreen',
                                bussinessNo: 2000043,
                              },
                            ],
                          },
                          {
                            path: '/admin**/business/statistics', // 统计分析
                            name: 'statistics',
                            icon: 'AreaChartOutlined',
                            iconCls: '&#xe626;',
                            id: 237,
                            routes: [
                              {
                                name: 'user-area', // 用户分布图
                                path: '/admin**/business/statistics/user-area',
                                locale: 'menu.business.statistics.user-area',
                                component: './Business/Statistics/UserArea',
                                bussinessNo: 2100001,
                              },

                              {
                                name: 'user-usage', // 机构数据统计
                                path: '/admin**/business/statistics/user-usage',
                                locale: 'menu.business.statistics.user-usage',
                                component: './Business/Statistics/UserUsage',
                                bussinessNo: 2100004,
                              },
                              {
                                name: 'company-usage', // 机构设备统计
                                path: '/admin**/business/statistics/company-usage',
                                locale: 'menu.business.statistics.company-usage',
                                component: './Business/Statistics/CompanyUsage',
                                bussinessNo: 2100003,
                              },
                              {
                                name: 'doctor-report-amount',
                                path: '/admin**/business/statistics/doctor-report-amount',
                                locale: 'menu.business.statistics.doctor-report-amount',
                                component: './Business/Statistics/DoctorReportAmount',
                                bussinessNo: 2100005,
                              },
                              {
                                name: 'deep-analysis-amount',
                                path: '/admin**/business/statistics/deep-analysis-amount',
                                locale: 'menu.business.statistics.deep-analysis-amount',
                                component: './Business/Statistics/DeepAnalysisAmount',
                                bussinessNo: 2100006,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },

                  {
                    name: 'login',
                    path: '/login',
                    component: './Login',
                  },
                  {
                    path: '/welcome',
                    name: 'welcome',
                    icon: 'smile',
                    component: './Welcome',
                  },
                  /*{
                    path: '/',
                    name: 'demo',
                    icon: 'smile',
                    routes: [
                      {
                        path: '/demo/hooks',
                        name: 'hooks',
                        // icon: 'smile',
                        component: './Demo/Hooks',
                      },
                      {
                        path: '/demo/quanju',
                        name: 'quanju',
                        // icon: 'smile',
                        component: './Demo/Quanju',
                      },
                      {
                        path: '/demo/quanju-class',
                        name: 'quanju-class',
                        // icon: 'smile',
                        component: './Demo/QuanjuClass',
                      },
                      {
                        name: 'echarts',
                        // icon: 'table',
                        path: '/demo/echarts',
                        component: './Demo/Echarts',
                      },
                      {
                        name: 'g2',
                        // icon: 'table',
                        path: '/demo/g2',
                        component: './Demo/G2',
                      },
                      {
                        name: 'Mobx',
                        // icon: 'table',
                        path: '/demo/mobx',
                        component: './Demo/Mobx',
                      },
                      {
                        name: 'mobx-class',
                        // icon: 'table',
                        path: '/demo/mobx-class',
                        component: './Demo/MobxClass',
                      },
                      {
                        name: 'table',
                        // icon: 'table',
                        path: '/demo/table',
                        component: './Demo/Table',
                      },
                    ],
                  },*/
                  {
                    component: './404',
                  },
                ],
              },
            ],
          },

          {
            component: './404',
          },
        ],
      },
    ],
  },
  {
    component: './404',
  },
];
