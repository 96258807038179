// api接口

import { PROXY_FUNC_DATA } from './proxys';

export type API = {
  /**
   *拦截用的
   *
   * @type {string}
   */
  url: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
 * ```js
  // 登录接口
  // 请求数据:
{
userNo: '账号加密的'
pwd: '密码加密的'
extraInfo: 'browser:%E8%B0%B7%E6%AD%8C,bversion:96.0.4664.45,bwidth:1920,bheight:1080'
channelVer: '1.1.1'
}
// 返回数据:
{
  "actionErrors": [],
  "actionMessages": ["登录成功"],
  "data": {
    "webVer": {
      "update_time": "2021-03-01 09:31:28",
      "version_name": "ECG_V3",
      "version_desc": "<p>版本<\/p>",
      "remark": "版本"
    },
    "roles": [{
      "systemId": 1,
      "roleId": 386,
      "isMain": 1,
      "roleName": "超级管理员",
      "homePage": "cust\/ecg\/custEcgRepList"
    }],
    "showVerLog": false,
    "homePage": "cust\/ecg\/custEcgRepList",
    "user": {
      "userTypeId": 256,
      "deptName": "正心医疗研发部",
      "companyId": 1,
      "userNo": "admin",
      "companyName": "正心医疗",
      "deptId": 86,
      "userSignPic": "sysuser\/sign\/1638259957548.jpg",
      "psdModified": 1,
      "userName": "系统管理员",
      "userId": 13,
      "userAvatarAddr": "http:\/\/test.zxthealth.com:8086\/ecg\/upload\/sysuser\/avatar\/1637798895021.jpg"
    },
    "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVU0VSX05PIjoiYWRtaW4iLCJleHAiOjE2MzgzNDUwODN9.mwVKPfRpJ1KVBHJ2Zy0Twgian2voPw7D7hlIFT0EmZI"
  }
}
    * ```
 */
  login: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *登录的公钥
   *
   * @type {string}
   */
  getKey: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *子级菜单
   *
   * @type {string}
   */
  menuChildren: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *父级菜单
   *
   * @type {string}
   */
  menuParent: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *权限
   *
   * @type {string}
   */
  bussinessNo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *我的病人
   *
   * @type {string}
   */
  users: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *ECG分析报告
   *
   * @type {string}
   */
  ecgReports: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *快速监测记录列表接口
   *
   * @type {string}
   */
  quickCheck: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *快速监测诊断结果编辑接口
   *
   * @type {string}
   */
  quickCheckResult: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *快速监测详情接口
   *
   * @type {string}
   */
  shortTerm: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *监测记录
   *
   * @type {string}
   */
  ecgRecords: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *监测记录 ---APP定位信息列表
   *
   * @type {string}
   */
  locationList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *设备绑定查询
   *
   * @type {string}
   */
  bindings: (data: PROXY_FUNC_DATA) => Promise<any>;
  powerLog: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *阻止上传/接触阻止上传
   *
   * @type {string}
   */
  blockReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *APP日志记录
   *
   * @type {string}
   */
  LogList: (data: PROXY_FUNC_DATA) => Promise<any>;
  deviceList: (data: PROXY_FUNC_DATA) => Promise<any>;
  eventWarningList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *预警列表
   *
   * @type {string}
   */
  Warnings: (data: PROXY_FUNC_DATA) => Promise<any>;
  judgeHrvAna: (data: PROXY_FUNC_DATA) => Promise<any>;
  hrvHourAna: (data: PROXY_FUNC_DATA) => Promise<any>;
  loadHrvHours: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *低振幅数据提醒
   *
   * @type {string}
   */
  Weak: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *智能筛查
   *
   * @type {string}
   */
  Screen: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查
   *
   * @type {string}
   */
  ScreenUser: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查列表
   *
   * @type {string}
   */
  screenList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查 ---提醒事件类型列表
   *
   * @type {string}
   */
  screenEventType: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查 ---提醒事件列表
   *
   * @type {string}
   */
  screenEventList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查 ---事件确认或者忽略
   *
   * @type {string}
   */
  screenEventConfirm: (data: PROXY_FUNC_DATA) => Promise<any>;
  screenNoteSubmit: (data: PROXY_FUNC_DATA) => Promise<any>;
  screenNoteList: (data: PROXY_FUNC_DATA) => Promise<any>;
  loadScreenDetail: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *ST-T异常 ---标记为事件
   *
   * @type {string}
   */
  markEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  sttChartData: (data: PROXY_FUNC_DATA) => Promise<any>;
  visitList: (data: PROXY_FUNC_DATA) => Promise<any>;
  submitVisit: (data: PROXY_FUNC_DATA) => Promise<any>;
  deleteVisit: (data: PROXY_FUNC_DATA) => Promise<any>;
  deleteEcg: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *消费者筛查 --- 高危事件
   *
   * @type {string}
   */
  ScreenHighEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *采样异常数据
   *
   * @type {string}
   */
  Sampling: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *智能筛查订单
   *
   * @type {string}
   */
  AnalyticOrders: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *签名审核
   *
   * @type {string}
   */
  Signature: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *分析解读
   *
   * @type {string}
   */
  InterpretationOrders: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *智能筛查订单
   *
   * @type {string}
   */
  IntelligentScreen: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *重置密码
   *
   * @type {string}
   */
  resetPwd: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *删除用户
   *
   * @type {string}
   */
  deleteUser: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *更新用户状态
   *
   * @type {string}
   */
  upUserStatus: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 加载报告信息
   * @type {string}
   */
  loadRepInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 加载测量记录信息
   * @type {string}
   */
  loadEcgInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 心搏列表
   * @type {string}
   */
  beatTypes: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * stt左侧类型列表
   * @type {string}
   */
  sttTypes: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * stt右侧缩略图列表
   * @type {string}
   */
  stts: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 心搏模板列表
   * @type {string}
   */
  matchs: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 心搏模板缩略图列表
   * @type {string}
   */
  beatList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 事件列表
   * @type {string}
   */
  eventTypes: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 事件缩略图列表
   * @type {string}
   */
  eventList: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 加载心搏类型信息
   * @type {string}
   */
  loadAbnMatch: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadAbnType: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 散点图中，向前向后插入心搏查询参考范围
   * @type {string}
   */
  loadAdditionReference: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 散点图中，向前向后插入心搏
   * @type {string}
   */
  insertAllSelBeats: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *解锁
   *
   * @type {string}
   */
  unlock: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *FTP上传
   *
   * @type {string}
   */
  uploadRepData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 打印报告时，用于查询报告的状态
   *
   * */
  printPDFStatus: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *当前登录用户
   *
   * @type {string}
   */
  userInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *删除报告
   *
   * @type {string}
   */
  delReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *数据还原
   *
   * @type {string}
   */
  initEcgAnaDataByRep: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *获取机构列表
   *
   * @type {string}
   */
  repCompanyListJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *纠偏
   *
   * @type {string}
   */
  initEcgAnaData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *数据重分析
   *
   * @type {string}
   */
  bdac: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *调参检波数据
   *
   * @type {string}
   */
  bdacFrag: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *调参检波状态
   *
   * @type {string}
   */
  bdacStatus: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *调参检波
   *
   * @type {string}
   */
  bdacSubmit: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *下载源文件
   *
   * @type {string}
   */
  loadAddr: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *统计数据
   *
   * @type {string}
   */
  loadReAnaData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *解锁ECG
   *
   * @type {string}
   */
  unlockEcg: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 解绑手机
   *
   * */
  unbindPhone: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *解绑设备
   *
   * @type {string}
   */
  unbind: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *申请签名
   *
   * @type {string}
   */
  anaAuditCompListJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *QTc统计
   *
   * @type {string}
   */
  QTc: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *机构迁移
   *
   * @type {string}
   */
  companyList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *更新
   *
   * @type {string}
   */
  loadEventInfoV3: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *提交审核
   *
   * @type {string}
   */
  audit: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *更新报告
   *
   * @type {string}
   */
  genAnaReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *userInfo
   *
   * @type {string}
   */
  custDetailJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *所有用户
   *
   * @type {string}
   */
  loadUsers: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *合并报告中的用户列表
   *
   * @type {string}
   */
  mergeRepUsers: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *合并报告
   *
   * @type {string}
   */
  mergeRep: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *合并报告-新接口
   *
   * @type {string}
   */
  mergeRepNew: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *收藏
   *
   * @type {string}
   */
  addToFav: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 合并报告中，获取有数据的日期区间
   * */
  hasRepDate: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *迁移机构
   *
   * @type {string}
   */
  changeCauth: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *保持登录
   *
   * @type {string}
   */
  keeplive: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *入库
   *
   * @type {string}
   */
  markSample: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *获取map
   *
   * @type {string}
   */
  baseCodeMap: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *迁移数据
   *
   * @type {string}
   */
  fileMove: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *绑定记录
   *
   * @type {string}
   */
  bindingLogJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *日志请求
   *
   * @type {string}
   */
  reqAppLog: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *批量已读
   *
   * @type {string}
   */
  markRead: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *订单解读
   *
   * @type {string}
   */
  endDisp: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *全部标记为已读
   *
   * @type {string}
   */
  delAllMessage: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *标记已读
   *
   * @type {string}
   */
  delMessage: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *获取更新日志
   *
   * @type {string}
   */
  loadCurWebVer: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *更改密码
   *
   * @type {string}
   */
  modifyPasswordSubmit: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *修改头像
   *
   * @type {string}
   */
  modifyAvatar: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *修改签名
   *
   * @type {string}
   */
  modifySign: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   *报告/监测记录详情 噪音tab--每分钟心率趋势图
   *
   * @type {string}
   */
  loadDetailBpmList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *报告/监测记录详情 噪音tab--时间散点图
   *
   * @type {string}
   */
  loadRRSByDay: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   *报告/监测记录详情 噪音tab--一小时RR间期趋势图
   *
   * @type {string}
   */
  loadDetailRRRangeList: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   *报告/监测记录详情 噪音tab--一分钟心电数据
   *
   * @type {string}
   */
  loadOneMinuteEcgData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *报告/监测记录详情 噪音tab--一分钟心电数据
   *
   * @type {string}
   */
  loadOneMinuteEcgDataNoPage: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *心搏编辑，列表
   *
   * @type {string}
   */
  beats: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *获取心电图详情信息
   *
   * @type {string}
   */
  abnInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *更改心搏类型
   *
   * @type {string}
   */
  modifyAnnoBatch: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *删除PDF报告
   *
   * @type {string}
   */
  deletePDF: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *心电特殊事件，列表
   *
   * @type {string}
   */
  ecgAbnListJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *申请签名
   *
   * @type {string}
   */
  applySign: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *获取报告打印的相关信息
   *
   * @type {string}
   */
  itemsHasData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *生成报告
   *
   * @type {string}
   */
  genReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *用户分布
   *
   * @type {string}
   */
  provData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *未读消息
   *
   * @type {string}
   */
  getUnReadMessage: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 报告-获取当前报告下的所有监测记录段信息
   * */
  loadEcgsByRep: (data: PROXY_FUNC_DATA) => Promise<any>;

  /*
   * 报告-获取当前报告下的多天报告
   * */
  loadRepDays: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 噪音list
   */
  loadNoiseData: (data: PROXY_FUNC_DATA) => Promise<any>;

  deleteNoise: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadAfVfData: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadLorenzData: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadLorenzDataNew: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修正散点图的数据
   */
  loadDiffData: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadHistogramData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 删除房扑
   */
  deleteAfVf: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 添加房扑
   */
  addAfVf: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *确认房扑房颤或室扑室颤
   */
  confirmAfVf: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *确认所有房扑房颤
   */
  confirmAllAfVf: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 退出登录
   * */
  logout: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 机构数据统计
   * */
  companyUserDataList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 机构设备统计
   * */
  corpData: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadReportPreviewData: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadFragList: (data: PROXY_FUNC_DATA) => Promise<any>;

  summary: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadSelectFragList: (data: PROXY_FUNC_DATA) => Promise<any>;

  loadSingleFragList: (data: PROXY_FUNC_DATA) => Promise<any>;

  updateReport: (data: PROXY_FUNC_DATA) => Promise<any>;

  deleteFrag: (data: PROXY_FUNC_DATA) => Promise<any>;

  replaceFrag: (data: PROXY_FUNC_DATA) => Promise<any>;

  updateFrag: (data: PROXY_FUNC_DATA) => Promise<any>;

  addFrag: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 是否在报告中打印
   */
  printFrag: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * PRO版本使用：保存统计结论
   */
  saveStatisticalConclusion: (data: PROXY_FUNC_DATA) => Promise<any>;
  saveAnaConclusion: (data: PROXY_FUNC_DATA) => Promise<any>;

  saveDoctorAdvice: (data: PROXY_FUNC_DATA) => Promise<any>;
  saveReportDoctorAdvice: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 保存统计数据
   */
  saveStatistics: (data: PROXY_FUNC_DATA) => Promise<any>;

  fragStyle: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修改心搏
   */
  batchModifyAnno: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修改心搏
   */
  moveAnno: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修正列表心搏形态
   */
  changeForm: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 全模板修改
   */
  batchModifyAnnoByBeat: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   *散点图中按类型全部修改
   * */
  batchModifyAnnoByType: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 删除模板
   */
  batchDeleteAnno: (data: PROXY_FUNC_DATA) => Promise<any>;

  batchDeletAnnoByBeat: (data: PROXY_FUNC_DATA) => Promise<any>;

  batchModifyAnnoByRange: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 合并模板
   */
  mergeTemplate: (data: PROXY_FUNC_DATA) => Promise<any>;

  splitTemplate: (data: PROXY_FUNC_DATA) => Promise<any>;

  updateEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  /*
   * 单发或速事件 修改心搏
   * */
  upbeatEvent: (data: PROXY_FUNC_DATA) => Promise<any>;

  deleteEvent: (data: PROXY_FUNC_DATA) => Promise<any>;

  confirmEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 标记噪音
   */
  modifyEventByNoise: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *添加特殊事件
   *
   * @type {string}
   */
  addEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *
   *修改为插入性单发
   */
  modifySingle: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 系统菜单管理
   */
  menuList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 自定义事件类型
   */
  loadManualEvents: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 医生报告量统计
   */
  reportAmount: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 医生报告量详情
   */
  reportPDFList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 申请报告列表
   */
  reportApplyList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 报告片段查询接口
   */
  checkOrderFragList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 标注事件更新接口
   */
  editFragTitle: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  选择用户报告
   */
  chooseRepList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  关联报告
   */
  connectedReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  删除申请记录
   */
  delReportApply: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  创建多天报告
   */
  creatMergeReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 智能筛查信息
   */
  anaResult: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 获取所有机构信息
   */
  loadDeptList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 获取报告预览统计
   */
  reportStatistics: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 全程浏览中报告留图
   */
  wholePreViewSaveEcg: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 全程浏览-瀑布图获取定义事件
   */
  waterFallGetEvents: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 获取原始数据（滤波后数据、心搏数据二进制流）地址
   */
  loadFileAddress: (data: PROXY_FUNC_DATA) => Promise<any>; // 获取原始数据（滤波后数据、心搏数据二进制流）地址
  /**
   * 添加噪音
   * {"noises":[{"ecgId":13,"sindex":168865,"eindex":297589},{"ecgId":1355,"sindex":16886,"eindex":297589}]}
   */
  addNoise: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 房颤转窦律
   */
  afvfsToSinus: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 用户事件
   */
  ecgEventListJson: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 报告深度分析
   */
  deepAnalysisList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 报告深度分析 ---批量标记完成
   */
  deepFinish: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * ECG分析报告 ---深度分析
   */
  deepAdd: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 遥测检查单/动态检查单 列表列表
   */
  reportCheckOrderList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 确费接口
   */
  confirmFee: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *关联报告列表
   */
  connectRepList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 选择报告
   */
  connectRep: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * ：删除检查单
   */
  delRepCheckOrder: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修改遥测报告用户信息
   */
  editCheckOrderUserInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 生成遥测报告
   */
  genRemoteReport: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 报告预处理列表
   */
  previewHandleList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 批量预处理
   */
  batchPreviewHandle: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 数据停止上传忽略提醒
   */
  ignoreTip: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 数据纠正(监测记录)
   */
  correctEcgData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 数据纠正(ECG分析报告)
   */
  correctRepData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 清除缓存(ECG分析报告)
   */
  cleanCache: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 自否开启自由留图模式
   */
  freeFragStyle: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修改报告用户信息
   */
  editUserInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 心律失常统计
   */

  dayEventList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 心率变异性
   */
  hrv: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 修改综合心率变异性
   */
  editHrvInfo: (data: PROXY_FUNC_DATA) => Promise<any>;

  /**
   * 室性节律中室早的人工指定和人工增加
   */
  overturn: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *只打印II导联
   */
  justPrintII: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 室性节律中室早中获取缩略图列表
   */
  loadSingleMatchs: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 翻转
   */
  updateWearWay: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 系统信息
   */
  sysInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 自定义修改规则
   */
  userDefineBeats: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 噪音处理中，片段选取后批量插入心搏
   */
  batchInsertAnno: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 运维预警---批量处理
   */
  batchDealWarning: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 运维预警---预警设置
   */
  warningLevelSet: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 运维预警---获取预警设置信息
   */
  warningSetInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 操作日志---获取操作日志列表信息
   */
  operationLogList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * APP请求记录---获取操作日志列表信息
   */
  apiLogList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 操作日志---获取操作用户列表信息
   */
  userList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 我的病人---紧急联系人列表
   *
   */
  userFamilyRelatedList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 用户信息 ---报告券列表
   */
  userVoucherList: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 用户信息 --用户报告券信息
   */
  userVoucherInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 用户信息 --激活报告券
   */
  userActiveCard: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 设备管理---禁用/启用
   */
  upStatus: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 分析报告、监测记录---取回
   */
  retrieval: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 分析报告、监测记录---归档
   */
  pushData: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 自由留图模式下，自定义房扑房颤/室扑室颤留图
   */
  toRepFrag: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 非自由留图模式下，自定义房扑房颤/室扑室颤留图
   */
  fragAFVF: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  token换取session
   */
  oapiAuthDoctor: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   *  更新报告同步状态
   */
  ekeSync: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * @Description: 获取用户设备信息
   * @return {*}
   */
  loadCustUserInfo: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 异常确认
   */
  abnormalConfirmation: (data: PROXY_FUNC_DATA) => Promise<any>;
  /**
   * 消费者筛查，留图操作post或查询get
   */
  screenFrag: (data: PROXY_FUNC_DATA) => Promise<any>;
  batchScreenEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  unConfirmedEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  screenEventDetail: (data: PROXY_FUNC_DATA) => Promise<any>;
  mockScreenEvent: (data: PROXY_FUNC_DATA) => Promise<any>;
  eventRemind: (data: PROXY_FUNC_DATA) => Promise<any>;
  systemNoticeList: (data: PROXY_FUNC_DATA) => Promise<any>;
  noticeList: (data: PROXY_FUNC_DATA) => Promise<any>;
  noticeDetail: (data: PROXY_FUNC_DATA) => Promise<any>;
  userEventSummary: (data: PROXY_FUNC_DATA) => Promise<any>;
  fragOrder: (data: PROXY_FUNC_DATA) => Promise<any>;
  pacemakerList: (data: PROXY_FUNC_DATA) => Promise<any>;
};

export type API2 = {
  [K in keyof API]: string;
};

const api: API2 = {
  // url: REACT_APP_ENV
  //   ? `${REACT_APP_ENV.split('@')[1]}/${REACT_APP_ENV.split('@')[0]}`
  //   : 'http://39.107.81.0:8086/ndev',
  url: REACT_APP_ENV ? `/${REACT_APP_ENV.split('@')[0]}` : '/ndev',
  // url: '/dev',
  login: '/loginJson.action',
  getKey: '/loginRdsPk.action',
  menuChildren: '/ums/menuData.action',
  menuParent: '/ums/menuBar.action',
  bussinessNo: '/basedata/userOpt.action',
  userInfo: '/basedata/userInfo.action',
  // 列表
  printPDFStatus: '/hs/rep/{keyId}/state',
  users: '/cust/custListJson.action',
  ecgReports: '/cust/ecg/ecgDailyListJson.action',
  quickCheck: '/cust/ecg/ecgShortTermListJson.action',
  quickCheckResult: '/cust/ecg/ecgShortTermResultEdit.action',
  shortTerm: '/hs/{keyId}/ecg/short/term',
  ecgRecords: '/cust/ecg/ecgListJson.action',
  locationList: '/cust/ecg/mach/locationListJson.action',
  blockReport: '/cust/ecg/blockReport.action',
  bindings: '/cust/machBindingJson.action',
  powerLog: '/cust/log/power.action',
  LogList: '/cust/ecg/logListJson.action',
  eventWarningList: '/cust/warn/bussiEventWaringList.action',
  deviceList: '/basedata/companyDeviceManagementList.action',
  Warnings: '/cust/urgent/warnings.action',
  judgeHrvAna: '/cust/ecg/judgeHrvAna.action',
  hrvHourAna: '/cust/ecg/hrvHourAna.action',
  loadHrvHours: '/cust/ecg/loadHrvHours.action',
  Weak: '/cust/ecg/signalWeakListJson.action',
  Screen: '/cust/ecg/ecgDailyListJson.action',
  ScreenUser: '/cust/ecg/screenDailyListJson.action',
  screenList: '/cust/ecg/screenV2ListJson.action',
  screenEventType: '/hs/rep/{keyId}/screen/event/types',
  screenEventList: '/hs/rep/{keyId}/screen/{page}/events',
  screenEventConfirm: '/hs/rep/{keyId}/screen/event',
  screenNoteSubmit: '/cust/ecg/screenNoteSubmit.action',
  screenNoteList: '/cust/ecg/screenNoteListJson.action ',
  loadScreenDetail: '/cust/ecg/loadScreenDetail.action',
  sttChartData: '/hs/rep/{keyId}/stt/chart/trend',
  markEvent: '/hs/excl/rep/{keyId}/event',
  visitList: '/cust/ecg/screen/visitListJson.action',
  submitVisit: '/cust/ecg/screen/submit.action',
  deleteVisit: '/cust/ecg/screen/delete.action',
  deleteEcg: '/cust/ecg/deleteEcg.action',
  ScreenHighEvent: '/hs/rep/{keyId}/screenEvent',

  Sampling: '/cust/appExpList.action',
  AnalyticOrders: '/cust/ma/maListJson.action',
  Signature: '/cust/ma/maListJson.action',
  InterpretationOrders: '/cust/mc/custMcListJson.action',
  IntelligentScreen: '/cust/ma/maListJson.action',
  repCompanyListJson: '/basedata/repCompanyListJson.action',
  companyUserDataList: '/report/companyUserDataList.action',
  corpData: '/report/corp/data.action',
  menuList: '/comm/menuList.action',
  ecgEventListJson: '/cust/ecg/ecgEventListJson.action',
  deepAnalysisList: '/cust/ecg/repDeepAnaListJson.action',
  deepFinish: '/hs/rep/deep/finish',
  deepAdd: '/hs/rep/deep/add',

  // 获取数据
  reportCheckOrderList: '/cust/ecg/reportCheckOrderList.action',
  confirmFee: '/cust/ecg/confirmFee.action',
  connectRepList: '/cust/ecg/connectRepList.action',
  connectRep: '/cust/ecg/connectRep.action',
  delRepCheckOrder: '/cust/ecg/delRepCheckOrder.action',
  editCheckOrderUserInfo: '/cust/ecg/editCheckOrderUserInfo.action',
  genRemoteReport: '/report/genRemoteReport.action',
  previewHandleList: '/cust/ecg/repPreDealList.action',
  batchPreviewHandle: '/cust/ecg/batchPreDealRep.action',
  custDetailJson: '/cust/custDetailJson.action',
  loadUsers: '/cust/ecg/loadUsers.action',
  mergeRepUsers: '/cust/ecg/mergeRepUsers.action',
  baseCodeMap: '/basedata/baseCodeMap.action',
  bindingLogJson: '/cust/bindingLogJson.action',
  loadCurWebVer: '/basedata/loadCurWebVer.action',
  beats: '/cust/ecg/beats.action',
  abnInfo: '/hs/ecg/{keyId}/episode',
  ecgAbnListJson: '/cust/ecg/ecgAbnListJson.action',
  itemsHasData: '/cust/ecg/itemsHasData.action',
  provData: '/report/provData.action',
  getUnReadMessage: '/ums/getUnReadMessage.action',
  loadEcgsByRep: '/cust/ecg/loadEcgsByRep.action',
  loadManualEvents: '/cust/ecg/loadManualEvents.action',
  reportAmount: '/report/corp/pdf.action',
  reportPDFList: '/report/corp/pdfList.action',
  reportApplyList: '/cust/ecg/reportApplyList.action',
  checkOrderFragList: '/cust/ecg/reportCheckOrderFragList.action',
  editFragTitle: '/cust/ecg/editFragTitle.action',
  chooseRepList: '/cust/ecg/chooseRepList.action',
  connectedReport: '/cust/ecg/connectedReport.action',
  delReportApply: '/cust/ecg/delReportApply.action',
  creatMergeReport: '/cust/ecg/createMergeReport.action',
  // 操作
  freeFragStyle: '/hs/excl/rep/{keyId}/fragStyle',
  ignoreTip: '/cust/ecg/ecgUploadTipIgnore.action',
  resetPwd: '/cust/resetPwd.action?struts.enableJSONValidation=true',
  deleteUser: '/cust/deleteUser.action?struts.enableJSONValidation=true',
  upUserStatus: '/cust/upUserStatus.action?struts.enableJSONValidation=true',
  unlock: '/cust/ecg/unlock.action',
  uploadRepData: '/cust/ecg/uploadRepData.action',
  delReport: '/cust/ecg/delReport.action',
  initEcgAnaDataByRep: '/hs/rep/{repId}/initialization',
  initEcgAnaData: '/hs/ecg/{ecgId}/initialization',
  correctEcgData: '/hs/ecg/{ecgId}/correct',
  correctRepData: '/hs/excl/rep/{repId}/correct',
  cleanCache: '/hs/{bussi}/{keyId}/ehcache/clean',
  bdac: '/cust/ecg/bdac.action',
  bdacFrag: '/hs/ecg/${keyId}/fragData',
  bdacStatus: '/hs/ecg/${keyId}/bdac/status',
  bdacSubmit: '/hs/ecg/${keyId}/bdac',
  loadAddr: '/cust/ecg/loadAddr.action',
  loadReAnaData: '/cust/ecg/loadReAnaData.action',
  unlockEcg: '/cust/ecg/unlockEcg.action',
  unbind: '/cust/unbind.action',
  unbindPhone: '/cust/unBindMobilePhone.action',
  anaAuditCompListJson: '/basedata/anaAuditCompListJson.action',
  QTc: '/cust/ecg/QTc.action',
  companyList: '/basedata/companyList.action',
  loadEventInfoV3: '/cust/ecg/loadEventInfoV3.action',
  audit: '/cust/ma/audit.action',
  genAnaReport: '/report/genAnaReport.action',
  mergeRep: '/cust/ecg/mergeRep.action',
  mergeRepNew: '/hs/rep/collection',
  addToFav: '/cust/ecg/addToFav.action',
  hasRepDate: '/cust/ecg/hasRepDate.action',
  changeCauth: '/cust/ecg/changeCauth.action',
  keeplive: '/keeplive.action',
  markSample: '/cust/ecg/markSample.action',
  fileMove: '/cust/ecg/fileMove.action',
  reqAppLog: '/cust/ecg/reqAppLog.action',
  markRead: '/cust/urgent/markRead.action',
  endDisp: '/cust/mc/endDisp.action',
  delAllMessage: '/ums/delAllMessage.action',
  delMessage: '/ums/delMessage.action',
  modifyPasswordSubmit: '/basedata/modifyPasswordSubmit.action',
  modifyAvatar: '/basedata/modifyAvatar.action',
  modifySign: '/basedata/modifySign.action',
  modifyAnnoBatch: '/cust/ecg/modifyAnnoBatch.action',
  deletePDF: '/hs/excl/rep/{repId}/delPdf',
  applySign: '/cust/ecg/applySign.action',
  genReport: '/report/genReport.action',
  logout: '/logoutJson.action',
  afvfsToSinus: '/hs/excl/{bussi}/{keyId}/afvfs/sinus',
  userDefineBeats: '/hs/excl/{bussi}/{keyId}/custom/beats',
  batchInsertAnno: '/hs/excl/rep/{repId}/range/fixedin/beats',
  batchDealWarning: '/cust/warn/batchDealWarning.action',
  warningLevelSet: '/cust/warn/warningLevelSet.action',
  warningSetInfo: '/cust/warn/warningNotifyList.action',
  operationLogList: '/hs/oper/{page}/logs',
  apiLogList: '/hs/oper/cust/api/logs',
  userList: '/basedata/getUsersByUserName.action',
  userFamilyRelatedList: '/cust/userFamilyRelatedList.action',
  userVoucherList: '/cust/userVoucherList.action',
  userVoucherInfo: '/cust/userVoucherInfo.action',
  userActiveCard: '/cust/userActiveCard.action',
  upStatus: '/basedata/upMachDeviceStatus.action',
  retrieval: '/hs/{bussi}/{keyId}/archive/retrieval',
  pushData: '/hs/{bussi}/archive/push',
  /*
   * 报告、数据详情页面
   * --- 噪音
   * */

  loadDetailBpmList: '/hs/{bussi}/{keyId}/bpms',
  loadDetailRRRangeList: '/hs/{bussi}/{keyId}/rrs',
  loadRRSByDay: '/hs/rep/{keyId}/rrsByDay',
  loadOneMinuteEcgData: '/hs/{bussi}/{keyId}/{page}/pager',
  loadOneMinuteEcgDataNoPage: '/hs/{bussi}/{keyId}/pager',
  loadNoiseData: '/hs/{bussi}/{keyId}/{page}/noises',
  deleteNoise: '/hs/excl/{bussi}/{keyId}/noises',
  loadAfVfData: '/hs/{bussi}/{keyId}/{page}/afvfs',
  deleteAfVf: '/hs/excl/{bussi}/{keyId}/afvfs',
  addAfVf: '/hs/excl/{bussi}/{keyId}/afvfs',
  confirmAfVf: '/hs/excl/{bussi}/{keyId}/afvfs/confirm',
  confirmAllAfVf: '/hs/excl/{bussi}/{keyId}/confirm/afvfs',
  editUserInfo: '/cust/ecg/editReportUserInfo.action',
  /**
   * add by Seeker
   */
  loadRepInfo: '/cust/ecg/loadRepInfo.action',
  loadAbnMatch: '/cust/ecg/rmatchs.action',
  loadAbnType: '/cust/ecg/abnType.action',
  loadAdditionReference: '/hs/{bussi}/{keyId}/beats/addition/reference',
  insertAllSelBeats: '/hs/excl/{bussi}/{keyId}/allsel/beats',
  /*
   心搏编辑/心电特殊事件接口
 */
  loadEcgInfo: '/cust/ecg/ecgInfo.action',
  sttTypes: '/hs/{bussi}/{keyId}/stt/types',
  stts: '/hs/{bussi}/{keyId}/{page}/stts',
  beatTypes: '/hs/{bussi}/{keyId}/beat/types',
  matchs: '/hs/{bussi}/{keyId}/{page}/matchs',
  beatList: '/hs/{bussi}/{keyId}/{page}/beats',
  eventTypes: '/hs/{bussi}/{keyId}/event/types',
  eventList: '/hs/{bussi}/{keyId}/{page}/events',
  loadRepDays: '/cust/ecg/loadRepDays.action',
  batchModifyAnno: '/hs/excl/{bussi}/{keyId}/beats',
  moveAnno: '/hs/excl/{bussi}/{keyId}/move/beats',
  changeForm:'/hs/{bussi}/{keyId}/mfile/deletion',
  batchModifyAnnoByBeat: '/hs/excl/{bussi}/{keyId}/match/beats',
  batchModifyAnnoByType: '/hs/excl/{bussi}/{keyId}/batch/beats',
  batchDeleteAnno: '/hs/excl/{bussi}/{keyId}/beats',
  batchDeletAnnoByBeat: '/hs/excl/{bussi}/{keyId}/match/beats',
  batchModifyAnnoByRange: '/hs/excl/{bussi}/{keyId}/range/beats',
  mergeTemplate: '/hs/excl/{bussi}/{keyId}/matchs',
  splitTemplate: '/hs/excl/{bussi}/{keyId}/split/matchs',
  updateEvent: '/hs/excl/{bussi}/{keyId}/up/events',
  upbeatEvent: '/hs/excl/{bussi}/{keyId}/upbeats/events',
  deleteEvent: '/hs/excl/{bussi}/{keyId}/events',
  confirmEvent: '/hs/excl/{bussi}/{keyId}/events/confirm',
  modifyEventByNoise: '/hs/excl/{bussi}/{keyId}/event/noises',
  addEvent: '/hs/excl/{bussi}/{keyId}/event',
  modifySingle: '/hs/excl/{bussi}/{keyId}/sve',
  addNoise: '/hs/excl/{bussi}/{keyId}/noise',
  updateWearWay: '/cust/ecg/updateWearWay.action',
  toRepFrag: '/hs/excl/rep/{keyId}/toRepFrag',
  fragAFVF: '/hs/excl/rep/{keyId}/frag/afvf',
  /*
    报告预览
  */
  loadReportPreviewData: '/hs/rep/{keyId}/data',
  loadFragList: '/hs/rep/{keyId}/{item}/episodes',
  summary: '/hs/rep/{keyId}/con/summary',
  loadSelectFragList: '/hs/rep/{keyId}/sel/{etype}/{page}/episodes',
  loadSingleFragList: '/hs/rep/{keyId}/{page}/veSingleTemplate',
  updateReport: '/hs/excl/rep/{keyId}/content',
  deleteFrag: '/hs/excl/rep/{keyId}/frag',
  replaceFrag: '/hs/excl/rep/{keyId}/vefrag',
  updateFrag: '/hs/excl/rep/{keyId}/updfrag',
  addFrag: '/hs/excl/rep/{keyId}/addfrag',
  printFrag: '/hs/excl/rep/{keyId}/item/print',
  saveStatisticalConclusion: '/hs/excl/rep/{keyId}/con/ana',
  saveAnaConclusion: '/cust/ecg/updateReportAnaConclusion.action',
  saveDoctorAdvice: '/cust/ecg/saveDoctorAdvice.action',
  saveReportDoctorAdvice: '/hs/excl/rep/{keyId}/doctorAdvice',
  saveStatistics: '/hs/excl/rep/{keyId}/statistics',
  fragStyle: '/cust/ecg/fragStyle.action',
  loadLorenzData: '/hs/{bussi}/{keyId}/scatter/lorenz',
  loadLorenzDataNew: '/hs/{bussi}/{keyId}/scatter/clorenz',
  loadDiffData: '/hs/rep/{keyId}/scatter/diff',
  loadHistogramData: '/hs/{bussi}/{keyId}/histogram/data',
  dayEventList: '/hs/rep/{keyId}/dayEventList',
  hrv: '/hs/rep/{keyId}/hrv',
  editHrvInfo: '/hs/excl/rep/{keyId}/editHrvInfo',
  overturn: '/hs/excl/rep/{keyId}/reverseFrag/{fragId}',
  justPrintII: '/hs/excl/rep/{keyId}/changeLead/{fid} ',
  loadSingleMatchs: '/hs/rep/{keyId}/{page}/ves/{rmatch}',
  loadDeptList: '/basedata/manageCompanyDeptTreeJson.action',
  reportStatistics: '/hs/rep/{keyId}/reportStatistics',
  wholePreViewSaveEcg: '/hs/excl/rep/{repId}/minToFrag',
  waterFallGetEvents: '/hs/rep/waterfall/events',
  systemNoticeList: '/other/systemNoticeList.action',
  noticeList: '/other/hasPublicNoticeList.action',
  noticeDetail: '/other/noticeDetailJSON.action',
  userEventSummary: '/hs/excl/rep/{keyId}/userEventSummary',
  fragOrder: '/hs/excl/rep/{repId}/fragOrder',
  pacemakerList: '/cust/ecg/reportFeaturePaceMakerListJson.action',

  /* 智能筛查
   */
  anaResult: '/hs/rep/ana/result',
  loadFileAddress: '/hs/{bussi}/{keyId}/info/files',
  sysInfo: '/sysInfo.action',

  oapiAuthDoctor: '/oapi/auth/doctor',
  ekeSync: '/cust/ecg/ekgSync.action',
  loadCustUserInfo: '/cust/ecg/loadCustUserInfo.action',
  /**
   * 异常确认
   */
  abnormalConfirmation: '/hs/rep/{keyId}/screen',
  screenFrag: '/hs/rep/{keyId}/screenFrag',
  batchScreenEvent: '/hs/rep/{keyId}/batchScreenEvent',
  unConfirmedEvent: '/hs/unconfirmedScreenEvent',
  screenEventDetail: '/hs/{keyId}/screenEventDetail',
  mockScreenEvent: '/hs/rep/1721/mockScreenEvent',
  eventRemind: '/basedata/userEventRemind.action',
};
export default api;
