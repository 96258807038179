export type AbnType = {
  /**
   * 事件定义必须字段
   */
  id?: number; // 事件定义必须字段
  /**
   * 事件定义必须字段、心电模板字段需要
   */
  key: string | any; // 事件定义必须字段、心电模板字段需要
  code?: string;
  /**
   * 事件定义必须字段 、心电模板字段需要
   */
  value: string; // 事件定义必须字段 、心电模板字段需要
  color?: string;
  /**
   * 事件定义非必须字段
   */
  extraValue?: number; // 事件定义非必须字段
  /**
   * 事件个数 、心电模板字段需要
   */
  numbers?: number | any; // 事件个数 、心电模板字段需要
  /**
   * 模板数据 、心电模板字段需要
   */
  templates?: AbnTypeTel[]; // 模板数据 、心电模板字段需要
  /**
   * 'type_no'
   */
  codeNO?: string; // 'type_no'
  /**
   * 'order_num'
   */
  orderNum?: number; // 'order_num',
  maxBpm?: number | undefined;
  minBpm?: number | undefined;
  eventCodeId?: number | undefined;
  isManualEvent?: number | undefined;
};
export type CDCD201_TYPE = {
  /**
   * @Description: 心博英文
   * @return {*}
   */
  code_string: string;
  /**
   * @Description: 心博编号
   * @return {*}
   */
  code_value: number;
  is_use: number;
  /**
   * @Description: 中文
   * @return {*}
   */
  text: string;
  /**
   * @Description: 心博编号
   * @return {*}
   */
  value: number;
  /**
   * 事件定义非必须字段
   */
  extraValue?: number; // 事件定义非必须字段
};
export type TYPE_loadManualEvents = {
  code_id: number;
  code_name: string;
  /**
   * 事件定义非必须字段
   */
  extra_value?: number | string; // 事件定义非必须字段
};
/**
 * 心搏类型模板模型
 */
export type AbnTypeTel = {
  numbers?: number | string; // 个数
  // editStatus: number;
  matchId: string;
  // matchType: number;
  matchFrag: number[];
  btype?: number;
  src?: number;
  color?: string; //心搏类型颜色
  code?: string; // 心搏类型英文描述 N、S、V等
  code_string?: string; // 心搏类型中文描述 正常、室早等
};
const ALL: AbnType = {
  id: -1,
  key: 'ALL',
  value: '全部',
  numbers: 0,
};

const N: AbnType = {
  key: 'N',
  value: '正常',
  color: '#363636',
  code: 'N',
};

const V: AbnType = {
  key: 'V',
  value: '室早',
  //color: '#ff0000',
  color: '#CD0000',
  code: 'V',
};

const S: AbnType = {
  key: 'S',
  value: '室上早',
  //color: '#9A36D1',
  color:'#7D26CD',
  code: 'S',
};

const RONT: AbnType = {
  key: 'T',
  value: 'RonT',
  //color: '#ff9900',
  color:'#1E90FF',
  code: 'RonT',
};

const X: AbnType = {
  key: 'X',
  value: '伪差',
  //color: '#2D8441',
  color:'#00EE00',
  code: 'X',
};
const B_X: AbnType = {
  key: 'B_X',
  value: '标伪差',
  color: '#528B8B',
  code: 'B_X',
};
const Z: AbnType = {
  key: 'Z',
  value: '噪音',
  color: '#333',
  code: 'NOISE',
};

const D: AbnType = {
  key: 'D',
  value: '删除',
  color: '#333',
  code: 'D',
};

const VE: AbnType = {
  key: 'E',
  value: '室性逸搏',
  code: 'Ve',
  color: '#1E90FF'
};

const SE: AbnType = {
  key: 'F',
  value: '房性逸搏',
  code: 'Se',
  color: '#1E90FF'
};

const JE: AbnType = {
  key: 'J',
  value: '交界性逸搏',
  code: 'Je',
  color: '#1E90FF'
};
const P: AbnType = {
  key: 'P',
  value: '起搏心搏',
  code: 'P',
  color: '#FFA500'
};
const VP: AbnType = {
  key: 'VP',
  value: '心室起搏心搏',
  code: 'VP',
  color: '#FFA500'
};

const AVP: AbnType = {
  key: 'AVP',
  value: '房室起搏心搏',
  code: 'AVP',
  color: '#FFA500'
};

const AP: AbnType = {
  key: 'AP',
  value: '心房起搏心搏',
  code: 'AP',
  color: '#FFA500'
};

/*const Z_A: AbnType = {
  key: 'Z_A',
  value: '标记当前页面为噪音',
  code: 'NOISE',
};*/
const X_D: AbnType = {
  key: 'X_D',
  value: '插入性单发',
  code: 'X_D',
};
/*const M_M: AbnType = {
  key: 'M_M',
  value: '自定义心搏修改',
  code: 'M_M',
};*/
const unknown: AbnType = {
  key: 'unknown',
  value: '未知类型',
  //color: '#1E90FF',
  color:'#00EE00',
  code: '?',
};
const A_F: AbnType = {
  key: 'A_F',
  value: '房扑/房颤',
  //color: '#C3025A',
  color:'#FF34B3',
  code: 'Af',
};
const V_F: AbnType = {
  key: 'V_F',
  value: '室扑/室颤',
  color: '#C3025A',
  code: 'Vf',
};
const A: AbnType = {
  key: 'A',
  value: '房颤',
  //color: '#C3025A',
  color:'#FF34B3',
  code: 'Af',
};
export {
  ALL,
  N,
  V,
  S,
  RONT,
  X,
  D,
  VE,
  SE,
  JE,
  VP,
  AVP,
  AP,
  Z,
  X_D,
  B_X,
  /*M_M,*/
  unknown,
  A_F,
  V_F,
};
const TYPES_ALL = [
  ALL,
  N,
  V,
  S,
  RONT,
  X,
  D,
  VE,
  SE,
  JE,
  VP,
  AVP,
  AP,
  Z,
  X_D,
  B_X,
  /*  M_M,*/
  unknown,
  A_F,
  V_F,
  A,
  P
];
const TYPES_0 = {
  flats: [N, V, S, X, D],
  dropDowns: [RONT, VE, SE, JE, VP, AVP, AP],
};

const TYPES_1 = {
  flats: [N, V, S, RONT, X, Z, D],
  dropDowns: [VE, SE, JE, VP, AVP, AP],
};

const TYPES_2 = [N, V, S];
//针对室上性二联律（203）室上性三联律（204）室上速（205）
const TYPES_3 = {
  flats: [N, V, X, Z],
  dropDowns: [],
};
//针对室性二联律（201）室性三联律（202）室速（206）
const TYPES_4 = {
  flats: [N, S, X, Z],
  dropDowns: [],
};
const TYPES_5 = {
  flats: [N, V, S, X, Z, D],
  dropDowns: [RONT, VE, SE, JE, VP, AVP, AP, /*M_M*/],
};
const TYPES_5_scatter = {
  flats: [N, V, S, X, D],
  dropDowns: [A,RONT, VE, SE, JE, VP, AVP, AP, /*M_M*/],
};
//针对室性单发
const TYPES_6 = {
  flats: [N, S, X, D],
  dropDowns: [],
};
//针对室上性单发
const TYPES_7 = {
  flats: [N, V, X, D],
  dropDowns: [],
};
//针对室上性成对 以及室上性三连发
const TYPES_8 = {
  flats: [N, V, X],
  dropDowns: [],
};
//针对室上性成对 以及室性三连发
const TYPES_9 = {
  flats: [N, S, X],
  dropDowns: [],
};
const TYPES_10 = {
  flats: [N, V, S, X, D, Z],
  dropDowns: [],
};
//针对室性单发
const TYPES_11 = {
  flats: [N, S, X, D],
  dropDowns: [],
};
//针对室上性单发
const TYPES_12 = {
  flats: [N, V, X, D],
  dropDowns: [],
};

const TYPES_13 = {
  flats: [X],
  dropDowns: [],
};
const TYPES_14 = {
  flats: [V, S, Z],
  dropDowns: [],
};
const TYPES_15 = {
  flats: [Z],
  dropDowns: [],
};

export {
  TYPES_0,
  TYPES_1,
  TYPES_2,
  TYPES_3,
  TYPES_4,
  TYPES_5,
  TYPES_5_scatter,//针对散点图
  TYPES_6,
  TYPES_7,
  TYPES_8,
  TYPES_9,
  TYPES_10,
  TYPES_11,
  TYPES_12,
  TYPES_13,
  TYPES_14,
  TYPES_15,
  TYPES_ALL,
};
